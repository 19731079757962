<template>
    <el-form label-width="100px">
        <!-- 余额 -->
        <el-form-item
            label="余额">
            {{"¥&nbsp;" + basic_data.balance + "&nbsp;元"}}
        </el-form-item>
        <!-- 提现中金额 -->
        <el-form-item
            label="提现中金额">
            {{"¥&nbsp;" + basic_data.withdrawal + "&nbsp;元"}}
        </el-form-item>
        <!-- 累计提现金额 -->
        <el-form-item
            label="累计提现金额">
            {{"¥&nbsp;" + basic_data.cumulative + "&nbsp;元"}}
        </el-form-item>
        <!-- 提现 -->
        <el-form-item
            label="提现">
            <el-input 
                v-model="withdrawal"
                class="center-user-balance-input"
                type="number"
                :max="basic_data.balance"
                :min="0">
            </el-input>
            <el-button
                type="primary"
                @click="handleButton">
                确定
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    data() {
        return {
            withdrawal: 0
        }
    },
    computed: mapState({
        outsideDialog: state => state.outsideDialog,
        basic_data: state => state.user.basic_data
    }),
    methods: {
        ...mapMutations([
            "changeOutsideDialog"
        ]),
        ...mapActions([
            "handleWithdraw"
        ]),
        handleButton(){
            if(this.withdrawal > this.basic_data.balance){
                this.$message({
                    showClose: true,
                    message: "提现金额不能大于余额！",
                    type: "error"
                })
                return
            }else if(this.withdrawal == 0){
                this.$message({
                    showClose: true,
                    message: "提现金额不能小于等于0！",
                    type: "error"
                })
                return
            }else{
                this.handleWithdraw(this.withdrawal)
                    .then(res => {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.type? "success": "error"
                        })
                        if(res.type) this.withdrawal = 0;
                    })
            }
        }
    },
}
</script>

<style scoped>
.center-user-balance-input{
    width: 200px;
    margin-right: 10px;
}
</style>